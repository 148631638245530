import React from 'react'
import { Link } from 'gatsby'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'

import LocalizedLink from '../LocalizedLink'
import styles from './style.module.css'
import knsblue from '../assets/images/logo/kns-blue.svg'
import locales from '../constants/locales'

class Header extends React.Component {
  constructor(props) {
    super(props)

    var location = this.props.location
    var array = location.split('/')
    var result = array[array.length - 1]
    if (result === 'cm') {
      result = 'platforms/cm'
    } else if (result === 'crm') {
      result = 'platforms/crm'
    } else if (result === 'fm') {
      result = 'platforms/fm'
    } else if (result === 'venuex') {
      result = 'platforms/venuex'
    }
    this.state = {
      tr: (
        <Link
          className={'en' === this.props.intl.locale ? styles.active : ''}
          key={'EN'}
          to={locales['en'].default ? `/${result}` : `/${result}`}
        >
          <span>{locales['en'].locale}</span>
        </Link>
      ),
      en: (
        <Link
          className={'tr' === this.props.intl.locale ? styles.active : ''}
          key={'TR'}
          to={
            locales['tr'].default ? '/' : `/${locales['tr'].path}/${location}`
          }
        >
          <span>{locales['tr'].locale}</span>
        </Link>
      ),
    }
  }

  componentDidMount() {
    let $ = window.$

    if (window.location.pathname === '/clientsPage') {
      $('.logo a img').attr('src', knsblue)
      $('.menu ul li a').css('color', '#1b437b')
      $('.header').css('background', '#ffffff')
      $('.language').css('border-color', '#1b437b')
      $('.language a span').css('color', '#1b437b')
      $('.other-page-social').show(100)
      $('#nav-toggle span').addClass('colorline')
      $('.numbers h1,.numbers h4').css('color', '#1b437b')
    }

    $('.mb-nav-btn').click(function() {
      $('.mobile-menu').fadeIn(500)
    })

    $('.close').click(function() {
      $('.mobile-menu').fadeOut(500)
    })
  }

  render() {
    return (
      <div className="container">
        <header className="header">
          <div className="row">
            <div className="col-3 logo">
              <a href="/">
                <img
                  src={require('../assets/images/logo/kns-blue.svg')}
                  alt="KNS"
                  title="KNS"
                />
              </a>
            </div>
            <div className="col-9">
              {/*<div href="#" className="language">*/}

              {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

              {/*</div>*/}
              <a href="#" id="nav-toggle" class="mb-nav-btn">
                <span />
              </a>
              <nav className="menu">
                <ul>
                  <li>
                    <LocalizedLink to="/aboutPage">
                      <FormattedMessage id="about" />
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/platformsPage">
                      <FormattedMessage id="platforms" />
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/supportPage">
                      <FormattedMessage id="supports" />
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/clientsPage">
                      <FormattedMessage id="clients" />
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/platforms/venuex">
                      <FormattedMessage id="Venuex" />
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/careerPage">
                      <FormattedMessage id="Career" />
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/contactPage">
                      <FormattedMessage id="Contact" />
                    </LocalizedLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>

        <div className="mobile-menu">
          <div class="close">
            <img src={require('../assets/images/icon/close-nav.svg')} />
          </div>

          <nav className="mb-nav">
            <ul>
              <li>
                <LocalizedLink to="/aboutPage">
                  <FormattedMessage id="about" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/platformsPage">
                  <FormattedMessage id="platforms" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/supportPage">
                  <FormattedMessage id="supports" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/clientsPage">
                  <FormattedMessage id="clients" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/platforms/venuex">
                  <FormattedMessage id="Venuex" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/careerPage">
                  <FormattedMessage id="Career" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/contactPage">
                  <FormattedMessage id="Contact" />
                </LocalizedLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    )
  }
}
Header.propTypes = { intl: intlShape.isRequired }

export default injectIntl(Header)
